import * as React from 'react';
import NavBar from './NavBar';
import { useParams } from 'react-router-dom';
import { useAuthenticator  } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

export default function VideoPlayer() {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  const navigate = useNavigate();

  let { name } = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  //const mediaURL = "https://d3hdybpgwjnj3g.cloudfront.net/" + name + ".mp4"

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  if(authStatus !== 'authenticated') {
    navigate('/');
  } else {
    return (
      <>
        <NavBar />
        <div>
            <video id="video_element" controls autoPlay style={{height:"auto", maxWidth:width}}>
                <source src={"https://d3hdybpgwjnj3g.cloudfront.net/" + name + ".mp4"} type="video/mp4"/>
            </video>
        </div>
      </>
    );
  }

}