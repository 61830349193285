import * as React from 'react';
import Contentcard from './Contentcard';
import Grid from '@mui/material/Unstable_Grid2';
import {Amplify} from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { useAuthenticator  } from '@aws-amplify/ui-react';
import { listMovies } from './graphql/queries';
import NavBar from './NavBar';
import CastProvider from 'react-chromecast';
import { useNavigate } from 'react-router-dom';


export default function CrApp() {

    const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
    const [movies, setMovies] = React.useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        fetchMovies();
    }, []);

    const fetchMovies = async () => {

        try {
            const Secret_Val = process.env.REACT_APP_API_KEY;

            Amplify.configure({
            API: {
                GraphQL: {
                endpoint: 'https://5by6pfni5ffj5m6vubaucccscm.appsync-api.us-east-2.amazonaws.com/graphql',
                region: 'us-east-2',
                defaultAuthMode: 'apiKey',
                apiKey: Secret_Val
                }
            }
            });
            const client = generateClient();
            
            const movieData = await client.graphql({ query: listMovies});
            const movieList = movieData.data.listMovies.items;
            setMovies(movieList);
            

        } catch (error) {
            console.log('error on fetching movies', error)
        }
    
    }

    if (authStatus !== 'authenticated') {
        navigate('/');
    }else {
        return (
            <>
                <NavBar />
                <Grid container spacing={2} sx={{paddingLeft:'2%', paddingRight:'2%'}}>
                        {movies.map((movie) => (
                        <CastProvider>
                            <Contentcard 
                            image={movie.image_url}
                            videoURL={movie.name}
                            />
                        </CastProvider>
                    ))}
                </Grid>
            </>
        );
    }
}
